.signature-checkbox-div {
    max-width: 20vh;
    width: 30%;
    margin-left: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.ui.checkbox.signature-checkbox-check {
    width: 100%;
}

@media screen and (max-width:1500px){
    .signature-checkbox-div {
        max-width: 30vh;
        width: 60%;
    }
}