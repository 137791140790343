.rdrDateDisplayWrapper {
    display: none;
}

.rdrDateRangePickerWrapper {
    border: 0.5px solid #D6D6D6;
}

@media only screen and (min-width: 960px) and (max-width: 1024px) {
    .rdrDateRangePickerWrapper {
        margin-left: 13px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1439px) {
    .rdrDateRangePickerWrapper {
        margin-left: 13px;
    }
}

@media only screen and (min-width: 1440px) {
    .rdrDateRangePickerWrapper {
        margin-left: 0px;
    }
}

.rdrStaticRange:hover .rdrStaticRangeLabel, .rdrStaticRange:focus .rdrStaticRangeLabel {
    background: #EBEBEB;
}
.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
    background: #EBEBEB;
}
.rdrDefinedRangesWrapper {
    font-size: 12px;
    color: #133F4F;
    width: 145px;
}

.rdrStaticRanges {
    display: flex;
    flex-direction: column;
}

.rdrStaticRange {
    border-bottom: 1px solid #EBEBEB;
    background: #fff;
    padding: 6.5px 0 6.4px 0;
}

.rdrStaticRange .rdrStaticRangeLabel:focus {
    background: #EBEBEB;
}
.rdrStaticRangeLabel {
    color: #133F4F;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    height: 39.6px;
}

.rdrInputRanges {
    display: none;
}

.rdrDateDisplay {
    color: #ECEAF9;
}

.rdrDateDisplayItem {
    border-radius: 4px;
    background-color: #ECEAF9;
    box-shadow: 0 1px 2px 0 #ECEAF9;
    border: 1px solid transparent;
}

.rdrDateDisplayItemActive input {
    border: 0px;
}

.rdrMonthAndYearWrapper {
    align-items: center;
    height: 25px;
    padding-top: 24px;
    width: 652px;
}

.rdrMonthAndYearPickers {
    display: none;
}

.rdrMonth {
    padding: 40px 0.833em 1.6666em 0.833em;
    border: 1px solid #EBEBEB;;
    margin-top: -25px;
    color: #000000;
    width: 326px;
}

.rdrMonthName {
    text-align: center;
    color: #000000;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    text-transform: capitalize;
    padding-bottom: 26px;
}

.rdrWeekDay {
    font-weight: 400;
    line-height: 2.667em;
}

.rdrWeekDays {
    color: red;
}

.rdrDays {
    color: red;
}

.rdrNextPrevButton {
    width: 48px;
    height: 49px;
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0px;
    background: #133F4F;
    color: #FFFFFF;
}


.rdrNextPrevButton:hover {
    background: #133F4F
}

.rdrNextButton i {
    margin: 0 0 0 7px;
    border-width: 8px 8px 8px 12px;
    border-color: transparent transparent transparent rgb(222, 231, 240);
    transform: translate(3px, 0px);
}

.rdrNextPrevButton i {
    display: block;
    width: 0;
    height: 0;
    padding: 0;
    text-align: center;
    border-style: solid;
    margin: auto;
    transform: translate(-3px, 0px);
}

.rdrPprevButton i {
    border-width: 8px 12px 8px 8px;
    border-color: transparent rgb(230, 233, 236) transparent transparent;
    transform: translate(-3px, 0px);
}

.rdrNextPrevButton i {
    display: table-row;
    position: absolute;
    position: relative;
    left: 20px;
    top: 1px;
    width: 0;
    height: 0;
    padding: 0;
    text-align: center;
    border-style: solid;
    margin: auto;
    transform: translate(-1px, 0px);
}

.rdrPprevButton i::after{
    content: url('../../../../../assets/icons/sent/leftArrowWhite.svg')
}

.rdrNextButton i::after{
    content: url('../../../../../assets/icons/sent/rightArrowWhite.svg')
}

.rdrWeekDay {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 18px;
    text-transform: uppercase;
}
.rdrDay {
    color: #000000;
}

.rdrDayWeekend {
    color: #000000;
}

.rdrDayStartOfMonth {
    color: #000000;
}

.rdrDayNumber {
    color: #000000 !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 16px !important;
}

.rdrInRange {
    background-color: #EBEBEB;
    color: #000000;
    border-radius: 0%;
    padding: 16px;
    margin: auto;
}

.rdrDateDisplayItem+.rdrDateDisplayItem {
    margin-left: 0;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span {
    color: #000000;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
}

.rdrDay:not(.rdrDayPassive) .rdrStartEdge~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected~.rdrDayNumber span {
    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.rdrSelected,
.rdrStartEdge,
.rdrEndEdge {
    background: #133F4F;
    left: 0;
    right: 0;
    border-radius: 0px;
    padding: 16px;
    margin: auto
}

.rdrSelected,
.rdrStartEdge {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.rdrSelected,
.rdrEndEdge  {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    left: 2px;
}

.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrStartEdge,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrStartEdge {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    right: 2px;
}

.rdrDayInPreview {
    border-top-width: 0;
    border-bottom-width: 0;
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
    background: rgba(255, 255, 255, 0.09);
    border: 0px;
}

.rdrDayToday:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge~.rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge~.rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrSelected~.rdrDayNumber span:after {
    display: none;
}

.rdrDayToday .rdrDayNumber span:after {
    content: '';
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 18px;
    height: 2px;
    border-radius: 2px;
    background: #ffff;
}


