:root{
    --toastify-color-light: #E8FDFE;
    --toastify-toast-width: 525px;
    --toastify-toast-min-height: 40px;
    --toastify-toast-max-height: 40px;
    --toastify-toast-padding: 0px;
}

.reset-menu{
    visibility: hidden;
}

@media only screen and (min-width: 768px) {    
    .show-menu{
        visibility: visible;
    }
    
    .hide-menu{
        visibility: visible;
    }

    .reset-menu{
        visibility: visible;
    }
}

@media only screen and (max-width: 768px) {
    #LeftMenuBar{
        transition: transform .3s cubic-bezier(0, .52, 0, 1);
        transform: translate3d(30vw,0,0);
    }
    
    .show-menu{
        transform: translate3d(0vw, 0, 0) !important;
    }
    
    .hide-menu{
        transform: translate3d(30vw,0,0) !important;
    }
}


@media only screen and (max-width: 768px) {
    #LeftMenuBar{
        transition: transform .3s cubic-bezier(0, .52, 0, 1);
        transform: translate3d(70vw,0,0);
    }
    
    .show-menu{
        transform: translate3d(0vw, 0, 0) !important;
    }
    
    .hide-menu{
        transform: translate3d(70vw,0,0) !important;
    }
}

@media only screen and (max-width: 425px) {
    #LeftMenuBar{
        transition: transform .3s cubic-bezier(0, .52, 0, 1);
        transform: translate3d(80vw,0,0);
    }
    
    .show-menu{
        transform: translate3d(0vw, 0, 0) !important;
    }
    
    .hide-menu{
        transform: translate3d(80vw,0,0) !important;
    }
}

.Toastify__toast-theme--light{
    padding: 0;
    margin-bottom: 4px;
}

.Toastify__toast-body{
    padding: 0;
}

.Toastify__toast-container{
    max-width: 525px;
    padding: 0;
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
}

.Toastify__toast-container--top-right{
    top: 8px;
    right: 8px;
}