.custom-menu-bar {
    bottom: 0;
    left: 0;
    top: 0;
    width: 15%;
    border-radius: 0;
    background-color: #25466C;

}

.iconBar {
    width:15%;
    position: fixed;
    bottom: 0;
}