.icon {
    margin-right: 10px !important;
}

.loading-container-report{
    display: block;
    position: relative;
    padding: 40px 0 20px 0;
  }
  
  .lds-spinner-reportLoading {
    color: official;
    display: flex;
    position: relative;
    width: 80px;
    height: 80px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
  }
  .lds-spinner-reportLoading div {
    transform-origin: 40px 60px;
    animation: lds-spinner-reportLoading 1.2s linear infinite;
  }
  .lds-spinner-reportLoading div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 7px;
    height: 33px;
    border-radius: 3.6px;
    background: #22C1D0;
  }

  .loading-mini{
    transform: scale(0.3);
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .lds-spinner-reportLoading div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner-reportLoading div:nth-child(2) {
    transform: rotate(50deg);
    animation-delay: -1s;
  }
  .lds-spinner-reportLoading div:nth-child(3) {
    transform: rotate(100deg);
    animation-delay: -0.9s;
  }
  .lds-spinner-reportLoading div:nth-child(4) {
    transform: rotate(140deg);
    animation-delay: -0.7s;
  }
  .lds-spinner-reportLoading div:nth-child(5) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner-reportLoading div:nth-child(6) {
    transform: rotate(230deg);
    animation-delay: -0.3s;
  }
  .lds-spinner-reportLoading div:nth-child(7) {
    transform: rotate(270deg);
    animation-delay: -0.1s;
  }
  .lds-spinner-reportLoading div:nth-child(8) {
    transform: rotate(310deg);
    animation-delay: 0s;
  }
  
  @keyframes lds-spinner-reportLoading {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
