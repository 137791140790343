/*reposo*/

.ui .segment{
    border: 0 !important; 
    box-shadow: none !important;
}

.labelInputFormMassiveLoad {
    display: block;
    border: 1px solid #B2B2B2;
    border-radius: 4px;
    position: relative;
    box-sizing: border-box;
    transition: .35s;
    margin-top: 0px;
}

.labelInputDate {
    display: block;
    border: 1px solid #B2B2B2;
    border-radius: 4px;
    position: relative;
    box-sizing: border-box;
    transition: .35s;
}

.labelInputFormMassiveLoadError {
    display: block;
    border: 1px solid #C3132E;
    border-radius: 4px;
    position: relative;
    box-sizing: border-box;
    transition: .35s;
    margin-top: 0px;
}

.labelInputFormMassiveLoadError ::placeholder{
    color: #C3132E !important;
}

.labelInputFormDateError {
    display: block;
    border: 1px solid #C3132E;
    border-radius: 4px;
    position: relative;
    box-sizing: border-box;
    transition: .35s;
}

.divInputDropdownFormMassiveLoad {
    display: block;
    border: 0px solid #B2B2B2;
    border-radius: 4px;
    height: 44px !important;
    position: relative;
    box-sizing: border-box;
    transition: .35s;
}

.inputDropdown{
    display: block;
    border: 1px solid #B2B2B2;
    border-radius: 4px;
    height: 44px !important;
    position: relative;
    box-sizing: border-box;
    transition: .35s;
}

.divInputDropdownFormMassiveLoadError {
    border: 0;
}

.inputFormMassiveLoad {
    border: none !important;
    background: transparent !important;
    display: block!important;
    outline: none !important;
    font-family: 'Roboto'!important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 14.4px !important; 
    
    
}

.inputFormMassiveLoad:not(:focus)::placeholder{color: #939393 !important;}

.inputFormMassiveLoad::placeholder{
    color: #939393;
}

.textArea {
    border: none !important;
    background: transparent !important;
    display: block!important;
    outline: none !important;
    font-family: 'Roboto'!important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 14px !important; 
    overflow-y: scroll;
    resize: none !important;
    width: 100%;
    height: 96px;
}

.inputFormDate {
    border: none !important;
    background: transparent !important;
    display: block!important;
    outline: none !important;
    font-family: 'Roboto'!important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 14px !important; 
}

.inputDropdownFormMassiveLoad:hover {
    border-bottom: none !important;
    margin-bottom: 15px !important;
}
.inputDropdownFormMassiveLoad {
    border: none !important;
    background: transparent !important;
    display: block!important;
    outline: none !important;
    font-family: 'Roboto'!important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 14px !important; 
    width: 100% !important;
}

@media only screen and (max-width: 768px)  {
    .labelInputDropdownFormMassiveLoad {
        color: rgba(0,0,0,.87);
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
    }

    .spanLabelInput {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
    }

    .spanLabelInputCompanyName {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
        visibility: hidden;
    }

    .spanLabelInputMassive{        
        color: red;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
    }

    .spanLabelInputDate {
        background-color: #FFFF;
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 14px; 
        padding: 6px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
        z-index: 1;
        width: 55%;
    }
    
    .spanLabelInputDV {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: -1px;
        position: absolute;
        transition: .35s;
    }

    .labelInputFormLabelsName{
        margin-top: 8px !important;
    }

    .labelInputFormLabelsNameError{
        margin-top: 8px !important;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px)  {
    .labelInputDropdownFormMassiveLoad {
        color: rgba(0,0,0,.87);
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
    }

    .spanLabelInput {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
    }

    .spanLabelInputCompanyName {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
        visibility: hidden;
    }

    .spanLabelInputMassive{        
        color: red;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
    }

    .spanLabelInputDate {
        background-color: #FFFF;
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 14px; 
        padding: 6px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
        z-index: 1;
        width: 55%;
    }
    
    .spanLabelInputDV {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: -1px;
        position: absolute;
        transition: .35s;
    }
}

@media only screen and (max-width: 1366px) and (min-width: 1025px)  {
    .labelInputDropdownFormMassiveLoad {
        color: rgba(0,0,0,.87);
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
    }

    .spanLabelInput {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 13px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
    }

    .spanLabelInputCompanyName {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 13px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
        visibility: hidden;
    }

    .spanLabelInputMassive{        
        color: transparent;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
    }
    
    .spanLabelInputDate {
        background-color: #FFFF;
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
        z-index: 1;
        width: 66%;
    }

    .labelInputDate {
        display: block;
        border: 1px solid #B2B2B2;
        border-radius: 4px;
        position: relative;
        box-sizing: border-box;
        transition: .35s;
        min-width: 0 !important;
    }
    
    .spanLabelInputDV {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: -1px;
        position: absolute;
        transition: .35s;
    }
}

@media only screen and (max-width: 1919px) and (min-width: 1366px)  {
    .labelInputDropdownFormMassiveLoad {
        color: rgba(0,0,0,.87);
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
    }

    .spanLabelInput {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
    }

    .spanLabelInputCompanyName {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
        visibility: hidden;
    }
    
    .spanLabelInputMassive{        
        color: transparent;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
    }

    .spanLabelInputDate {
        background-color: #FFFF;
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
        z-index: 1;
        width: 68%;
    }
    
    .spanLabelInputDV {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: -1px;
        position: absolute;
        transition: .35s;
    }
}

@media only screen and (max-width: 2560px) and (min-width: 1920px)  {
    .labelInputDropdownFormMassiveLoad {
        color: rgba(0,0,0,.87);
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
    }

    .spanLabelInput {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
    }

    .spanLabelInputCompanyName {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
        visibility: hidden;
    }
    
    .spanLabelInputMassive{        
        color: transparent;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
    }

    .spanLabelInputDate {
        background-color: #FFFF;
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
        z-index: 1;
        width: 70%;
    }
    .spanLabelInputDV {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: -1px;
        position: absolute;
        transition: .35s;
    }
}
/*Focus*/
.labelInputFormMassiveLoad:focus-within {
    border-color: #22C1D0;
}

.divInputDropdownFormMassiveLoad:focus-within {
    border-color: #22C1D0;
}

.labelInputDate:focus-within {
    border-color: #22C1D0 !important;
}

.spanLabelInputFocus {
    color: #22C1D0;
}

/*top focus*/
.inputFormMassiveLoad:focus ~ span {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 5px;
    left: 8px;
    top: -9px;
    font-size: 12px;
    background: white;
    color: #22C1D0;
    position: absolute;
}

.textArea:focus ~ span {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 5px;
    left: 8px;
    top: -9px;
    font-size: 12px;
    background: white;
    color: #22C1D0;
    position: absolute;
}

.inputFormDate:focus ~ span {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 5px;
    left: 8px;
    top: -9px;
    font-size: 12px;
    background: white;
    color: #22C1D0;
    position: absolute;
    width: auto;
}

.inputDropdownFormMassiveLoad:focus ~ span {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 5px;
    left: 8px;
    top: -10px;
    font-size: 12px;
    background: white;
    color: #22C1D0;
    position: absolute;
}

/*top*/
.spanLabelInputAttributeListTop {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 5px;
    left: 8px;
    top: -10px;
    font-size: 12px;
    background: white;
    color: #22C1D0;
    position: absolute;
    font-weight: 100;
}

.spanLabelInputDVTop {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 5px;
    left: 4px;
    top: -10px;
    font-size: 12px;
    background: transparent;
    color: #22C1D0;
    position: absolute;
}

.labelInputDropdownFormMassiveLoadTop {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 5px;
    left: 8px;
    top: -8px;
    font-size: 12px;
    background: white;
    color: #22C1D0;
    position: absolute;
    line-height: normal;
}

.spanLabelInputCompanyNameTop {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 5px;
    left: 8px;
    top: -10px;
    font-size: 12px;
    background: white;
    color: #22C1D0;
    position: absolute;
    font-weight: 100;
}

/*top error*/
.spanLabelInputCompanyNameTopError {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 5px;
    left: 8px;
    top: -10px;
    font-size: 12px;
    font-family: 'Roboto';
    font-weight: 100;
    background: white;
    color: #C3132E;
    position: absolute;
}

.spanLabelInputAttributeListTopError {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 5px;
    margin-top: -10px;
    margin-left:8px;
    font-size: 12px;
    font-family: 'Roboto';
    font-weight: 100;
    background: white;
    color: #C3132E;
    position: absolute;
}

.spanLabelInputDVTopError {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 5px;
    left: 4px;
    top: -10px;
    font-size: 12px;
    background: white;
    color: #C3132E;
    position: absolute;
}

.gridColumn {
    padding: 0.7rem !important;
}

.react-switch {
    margin-top: 8px;
    top: 6px !important;
}

.spanSwitch {
    margin-right: 7px;
    color: #000000;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
}

.input-error4 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #C3132E;
    top: 14px;
    left: 12px;
    position: absolute;
    transition: .35s;
    background-color: #FFFF;
}

.input-error-attribute-list {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    color: #C3132E;
    padding: 16px;
    position: absolute;
    transition: .35s;
}

.input-error-attribute-list>.text{
    color: #C3132E !important;
}

.input-error2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    color: #C3132E;
    top: 14px;
    left: 16px;
    position: absolute;
    transition: .35s;
    z-index: 2 !important;
}
.input-error2 :nth-child(even) {
    background:#F7F7F7 !important;
}

.input-textarea-error {
    background: white;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #C3132E;
    
    left: 16px;
    position: absolute;
    transition: .35s;
    
}

input:not([type=checkbox])::-webkit-calendar-picker-indicator{
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url('../../assets/icons/dateIcon.svg') no-repeat;
    width: 20px;
    height: 20px;
    border-width: thin;
}

.ui.selection.active.dropdown  {
    z-index: 3 !important;
}
.ui.selection.active.dropdown:hover  {
    z-index: 3 !important;
}

.ui.inverted.popup{
    display: none !important;
}

.iconDisable {
    position: absolute;
    top: 10px;
    left: 90%;
}

.labelInputFormLabelsNameError {
    margin-top: 10px;
    display: block !important;
    display: block;
    border: 1px solid #C3132E;
    border-radius: 4px;
    padding-bottom: 2px;
    position: relative;
    box-sizing: border-box;
    transition: .35s;
}

.labelInputFormLabelsName {
    margin-top: 10px;
    display: block !important;
    display: block;
    border: 1px solid #B2B2B2;
    border-radius: 4px;
    position: relative;
    box-sizing: border-box;
    transition: .35s;
}

.spanLabelInputBottomError {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 5px;
    left: 8px;
    top: -10px;
    font-size: 12px;
    font-family: 'Roboto';
    font-weight: 100;
    background: white;
    color: #C3132E;
    position: absolute;
}

.InputFormLabelsName {
    border: none !important;
    background: transparent !important;
    display: block!important;
    outline: none !important;
    font-family: 'Roboto'!important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 14px !important; 
}

.InputFormLabelsName:focus ~ span {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 5px;
    left: 8px;
    top: -9px;
    font-size: 12px;
    background: white;
    color: #22C1D0;
    position: absolute;
}

