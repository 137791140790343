.attached-title-element {
    max-width: 80%;
}

.ui.basic.label {
    height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.ui.label>.icon {
    width: auto;
    margin: 0 3.33px 0 0;
}

.ui.label {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 24px;
}

.ui.image.label img {
    width: 19px !important;
    height: 19px !important;
    margin-top: -2px !important;
    margin-left: -2px;
}

.text-title-document {
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    width: 90%;
}
.text-title {
    margin-top: 0;
    padding-top: 1px;
    padding-bottom: 0px;
    margin-bottom: 0px !important; 
}

@media only screen and (max-width: 425px){
    .text-title {
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        width: 85%;
    }
}

.ui.page.modals.dimmer.active {
    background: rgba(0, 0, 0, 0.5) !important;
    z-index: 5000 !important;
    justify-content: center;
}


.buttonPopupPdf:hover{
    background-color: #E4E4E4;
    color: #7C7C74;
}

.dimmable:not(body) {
    position: inherit !important;
}
