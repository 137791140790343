.labelDashboard{
    display: block;
    border: 1px solid #7C7C74;
    border-radius: 4px;
    box-sizing: border-box;
    transition: .35s;
}
.labelDashboardActive{
    display: block;
    border: 1px solid #22C1D0;
    border-radius: 4px;
    box-sizing: border-box;
    transition: .35s;
}

.divSearch{
    width: 410px;
}
@media only screen and (max-width: 1900px) {
    .divSearch{
        width: 100%;
    }
}