.ui.dropdown>.text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    white-space: nowrap !important;
    overflow: hidden !important;
    width: 90% !important;
    text-overflow: ellipsis;
}