.dropdownFor {
    border: none !important;
    width: 95%!important;
    border-bottom: 1px solid #DADADA !important;
    border-radius: 0 !important;
    z-index: 1;
    padding-right: 10px;
   
}

.dropdownForError {
    border: none !important;

    border-bottom: 1px solid #BA3555 !important;
    border-radius: 0 !important;
}

.forContent {
    width: 75%;
    max-height: 34px;
    display: inline-flex;
}

@media only screen and (max-width: 992px) {
    .forContent {
        margin-top: 35px;
        width: 100%;
        display: block;
    }
}

@media only screen and (min-width: 960px) and (max-width: 1024px) {
    .ui.multiple.dropdown > .label {
        font-size: 7px;
    }
}

.labelFor {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    color: #B2B2B2;
    z-index: 3;
    position: absolute;
    top: 13px;
}

.ui.default.dropdown:not(.button) > input:focus ~ .text, .ui.dropdown:not(.button) > input:focus ~ .default.text {
    color: #BA3555 !important;
}

.ui.default.dropdown:not(.button) > .text, .ui.dropdown:not(.button) > .default.text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #BA3555 !important;
    margin-left: 38px;
}




