#toolbar{
    background: #E4E4E4 ;
    border-radius: 8px !important;
    float: right;
    width: 82%;
    height: 40px;
    padding: 8px;
    border: 0;
    margin-top:15px;
    display: inline-table;
}

@media only screen and (max-width: 992px){
    #toolbar {
        float: left;
        width: 100%;
    }
}

@media only screen and (max-width: 500px){
    .ql-formats {
        margin: 0px !important;
    }

    .ql-toolbar.ql-snow .ql-formats {
        margin: 0px !important;
    }
    #toolbar {
        display: flex;
        justify-content: space-between;
        float: left;
        width: 100%;
        align-items: center;
    }
    .ql-snow .ql-picker-label{
        padding-left: 0;
        padding-right: 0;
    }
}
.contentToolbarAndButton{
    width:100%;
    padding-bottom: 200px !important;
}
@media only screen and (max-width: 768px){
    .contentToolbarAndButton{
        width:100%;
        padding-bottom: 0 !important;
    }
}




#editor-container{
    width:100%;
    height: 45%;
    overflow-x: hidden;
    overflow-y: auto;
    border:0;
    padding: 0;
    font-size: 16px;
    color: #000000;
}

@media only screen and (max-width: 1500px) {
    #editor-container{
        height: 140px;
    }
}

#container-files{
    display: contents;
}

.ql-editor{
    padding: 10px 0px;
}

p{
    line-height: normal;
}

::selection {
    background-color: #22C1D0;
    color: white;
}

.ql-editor.ql-blank::before {
    color: #979E9F;
    font-size: 16px;
    font-style: inherit;
    font-family: 'Roboto', 'Mulish', sans-serif;
    left: 0;
}

#attachment{
    background-image:url('../../../components/Toolbar/attachment.svg');
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
}
#attachment_disabled{
    background-image:url('../../../components/Toolbar/attachment_disabled.svg');
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
}

.ql-snow.ql-toolbar button {
   width: auto;
   height: auto;
   padding: 3px 0px;
}

.ql-snow.ql-toolbar button img{
    vertical-align: middle;
}

.ql-snow.ql-toolbar button.ql-active{
    background-color: rgba(0, 0, 0, 0.25);
}

.ql-snow.ql-toolbar button.ql-align{
    padding: 3px 3px;
}

.ql-snow.ql-toolbar button.ql-color{
    padding: 3px 3px;
}

.ql-snow .ql-color-picker{
    width: auto;
    height: auto;
}

.ql-snow.ql-toolbar .ql-color .ql-picker-label img{
    vertical-align: middle;
}

.ql-snow .ql-picker.ql-size {
    width: auto;
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg{
    display: none;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before{
    content: url('../../../components/Toolbar/size.svg') !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before{
    font-size: 10px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before{
    font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before{
    font-size:22px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before{
    font-size: 28px;
}

.ql-snow.ql-toolbar button.ql-list{
    padding: 3px 3px;
}

.ql-snow.ql-toolbar button.ql-indent{
    padding: 3px 3px;
}

/*Contenido de los options*/
.ql-snow .ql-picker.ql-expanded .ql-picker-options{
    top: auto;
    bottom: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
}


.ql-snow .ql-color-picker .ql-picker-options {
    width: 294px;
    padding: 6px 6px;
}

.ql-toolbar.ql-snow .ql-picker-options{
    border: 1px solid #d4d4d5;
    border-radius: 3px;
}

.ql-snow .ql-color-picker .ql-picker-item {
    box-shadow: rgb(221, 221, 221) 0px 0px 0px 1px;
    margin: 3px;
}

.ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl){
    padding: 0;
}

.ql-editor li::before{
    display:none;
}

.loading-container-button{
    padding: 0;
    position: relative;
    padding: 0;
    top: 3px;
    width: 22px;
    height: 22px;
}

.lds-spinner-sendingWrite {
    color: official;
    display: flex;
    position: absolute;
    width: 10px;
    height: 10px;
    top: -40%;
    right:160%;
    transform: translate(-50%, -50%);
  
  }
  .lds-spinner-sendingWrite div {
    transform-origin: 40px 20px;
    animation: lds-spinner-reportLoading 1.2s linear infinite;
  }
  .lds-spinner-sendingWrite div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 10px;
    left: 39px;
    width: 2px;
    height: 5px;
    border-radius: 3.6px;
    background: #ffffff;
  }
  .lds-spinner-sendingWrite div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner-sendingWrite div:nth-child(2) {
    transform: rotate(50deg);
    animation-delay: -1s;
  }
  .lds-spinner-sendingWrite div:nth-child(3) {
    transform: rotate(100deg);
    animation-delay: -0.9s;
  }
  .lds-spinner-sendingWrite div:nth-child(4) {
    transform: rotate(140deg);
    animation-delay: -0.7s;
  }
  .lds-spinner-sendingWrite div:nth-child(5) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner-sendingWrite div:nth-child(6) {
    transform: rotate(230deg);
    animation-delay: -0.3s;
  }
  .lds-spinner-sendingWrite div:nth-child(7) {
    transform: rotate(270deg);
    animation-delay: -0.1s;
  }
  .lds-spinner-sendingWrite div:nth-child(8) {
    transform: rotate(310deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner-sendingWrite {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }