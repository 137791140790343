
.option-hide{
    display:block;
}

.option-hide-res{
    display:block;
    position: relative;
}

.leftArrow {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    z-index: 1;
}

.leftArrowRes {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    z-index: 1;
    float:left;
    width: auto !important;
}

.rightArrow {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    z-index: 1;
}

.rightArrowRes {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    z-index: 1;
    float:right;
    width: auto !important;
}

.responsive {
    justify-content: center;
    align-items: center;
    height: 100%;
}

.title-preview {
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    color: #707070;
    margin-bottom: 4px;
}

.element-filler {
    height: 100%;
    width: 100%;
}

.element-container {
    flex-direction: column;
    padding: 48px 20px 75px 19px;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-position-y: 50vh;
}

.ContainerTypeViewRes{
    width: 100%;
    height:100%;
}

.svg-preview-res {
    width:100%;
    height:100%;
}

.notification-header-container {
    position: absolute;
    height: 8%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
}

.notification-headerr-container {
    height: 100%;
    padding: 48px 20px 75px 19px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%
}

.notification-filter-container {
    position: absolute;
    height: 8%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
}

.notification-message-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 90%;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

/*----------------------------------------*/
.notification-date{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 20px 15px 15px 15px;
}

.notification-message-container-pro {
    height: 88%;
    overflow-y: scroll;
    padding-bottom: 30px;
}

.text-date{
    font-family: 'Roboto';
    font-size: 11px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: right;
    color: #73736A;
}

.notification-title{
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #000000;
}

.notification-subtitle{
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    color: #000000;
    padding: 0px 15px;
}

.notification-description{
    font-family: 'Roboto';
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
    color: #73736A;
    padding: 0px 15px;
}

.notification-documents{
    margin: 10px;
}

.element-document{
    height: 42px;
    margin-bottom: 10px;
    display: flex;
    background: #F2F2F2;
    align-items: center;
    justify-content: space-around;
    padding: 13px 8px 13px 8px;
    border-radius: 4px;
    justify-content: space-between;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
}

.document-name{
    width: 75%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: start;
}

.notification-title-container{
    display: flex;
    justify-content: space-between;
    padding: 0px 15px;
}

.notification-buttons-sign{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    background: #FFFFFF;
    width: 100%;
    bottom: 0;
    height: 60px;
    gap: 8px;
    padding: 0px 16px;
    -webkit-box-shadow: 0px -2px 0px 0px rgba(0,0,0,0.08);
    -moz-box-shadow: 0px -2px 0px 0px rgba(0,0,0,0.08);
    box-shadow: 0px -2px 0px 0px rgba(0,0,0,0.08);
}

.button-sign-rejected{
    font-family: 'Roboto';
    font-size: 15px;
    line-height: 21.09px;
    font-weight: 500;
    color: #003057;
    background: #FFFFFF;
    text-align: center;
    padding: 5px;
    width:50%;
    height: 35px;
    border: 2px solid #003057;
    border-radius: 22px;
}

.button-sign-active{
    font-family: 'Roboto';
    font-size: 15px;
    line-height: 21.09px;
    font-weight: 500;
    color: #FFFFFF;
    background: #003057;
    text-align: center;
    padding: 5px;
    width:50%;
    height: 35px;
    border: 2.5px solid #003057;
    border-radius: 22px;
}


.contents-previewNotification{
    width:100%;
    height:100%;
    background-image: url('../../../assets/icons/write/wspImage1.png');
    background-size: cover;
    position: relative;
    display:flex;
    align-items: flex-end;
}

.contents-previewList{
    width:100%;
    height:100%;
    overflow-y:hidden;
    position: relative;
}

.content-filters{
    display:flex;
    justify-content: space-around;
    margin: 18px 10px;
}

.content-input-search{
    width:198px;
    height: 27px;
    border-radius: 20px;
    font-family: 'Roboto';
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: left;
    background: #F2F2F2;
    color : #7C7C74;
    display:flex;
    align-items: center;
    padding: 0px 10px;
    justify-content: space-between;
}

.content-notification-status{
    width:92%;
    margin: 0px auto;
    display: flex;
}

.svg-and-contents-read-confirmation-pro{
    width: 92%;
    margin: 10px auto 0px auto;
}

.content-notification-disabled{
    width:92%;
    margin: 0px auto;
    margin-top: 5px;
}

.content-notification-menu{
    width:100%;
    display: flex;
    position: absolute;
    bottom: 0;
}

.container-notification-message{
    width:100%;
    height: 60px;
    margin: auto;
    background: #F9F9F9;
    border-left: 2px solid #0E3B52;
    padding: 5px 5px;
    overflow-y: hidden;
}

.contents-segmentNotification{
    width:100%;
    height:65%;
}

.SegmentNotification-pro{
    width: 90%;
    height: 78px;
    background: #FFFFFF;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 6px;
    margin: auto;
  }

/*----------------------------------------*/

.notification-list-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 90%;
    overflow-x: auto;
}

.notification-container {
    height: 100%;
    background-color: #E4E4E4;
    padding: 5px 5px;
    border-radius: 5px;
    position: absolute;
    left: 10%;
    right: 0;
    bottom: 0;
    width: 80%;
    overflow-x: clip;
}

.notification-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 95%;
}

.svg-and-contents {
    position: absolute;
    height: 715px;
    padding: 48px 20px 75px 19px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 340px;
    margin: auto;
}

.contents-previewMessage{
    width:100%;
    height:100%;
    background: #FFFFFF;
    position: relative;
    border-radius: 5px;
}

.active-check-notificationSigned{
    padding-bottom: 60px;
}

.svg-and-contents-read-confirmation {
    position: absolute;
    height: 715px;
    padding: 48px 20px 75px 19px;
    top: -175px;
    bottom: 0;
    left: 0;
    right: 1.8%;
    width: 285px;
    margin: auto;
    display: flex;
    align-items: center;
}

.svg-and-contents-wsp {
    position: absolute;
    height: 100%;
    padding: 48px 20px 75px 19px;
    top: -120px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 84%;
    margin:auto;
    display: flex;
    align-items: center;
}
.background-butterflies{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.notification-pill{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.SegmentNotification{
  width: 100%;
  height: 65px;
  background: #FFFFFF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 6px;
}

.Header{
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: center;
}

.HeaderContent{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Date {
    font-family: 'Roboto';
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: right;
    color: #003057;

}

.Message{
    width: 100%;
    height: auto;
    font-family: 'Roboto';
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
    display: flex;
    align-items: center;
  color: #6C7374;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.Title{
  margin-left: 6px;
  width: 100%;
  font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
  color: #000000;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}


.title-message-notification{
    width: 70%;
    float:left;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 19px;
    color: #000000;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.hour-message-notification{
    width: 30%;
    float:right;
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 19px;
    color: #000000;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: right;
}

.subject-message-notification{
    width: 70%;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: #000000;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.text-message-notification{
    width: 70%;
    height:20px;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 18px;
    color: #7C7C74;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.text-message-notification p span{
    font-size: 8px !important;
}

.iconTitle{
    width:15px;
}

.iconArrowLeft{
    width:30px;
    float:left;
}

.iconArrowRight{
    width:30px;
    float:right;
}


@media screen and (min-width: 2200px) {
    .svg-and-contents {
        height: 805px;
        width: 391.83px;
        padding: 47px 20px 75px 25px;
    }
    
    .notification-message-container {
        position: absolute;
        left: 10%;
        right: 0;
        bottom: 0;
        width: 80%;
        overflow-x: auto;
        top: 22% ;
    }
    .notification-pill{
        top: 6.5%;
        left: 6.3%;
        height: 90%;
    }
    .background-butterflies{
        top: 15%;
        left: 3%;
        height: 72%;
    }
}

@media screen and (min-width: 1930px) and (max-width: 2200px) {
   
    .notification-header-container {
        top: 13%;
        padding: 0 10% 0 10%;
    }
    .notification-filter-container {
        top: 19%;
        padding: 0 11% 0 11%;
    }
    .notification-message-container {
        top: 22%;
        padding: 2% 2% 0 12%;
        height: 52%;
    }
    .notification-list-container {
        top: 26%;
        padding: 2% 0 0 12%;
        height: 52%;
    }
    .notification-container {
        top: 13%;
        padding: 0 0 0 0;
        height: 68%;
    }
    
    .notification-buttons{
        top: 72%;
        padding: 0 8% 0 12%;
    }
    .notification-pill{
        top: 6.5%;
        left: 6.3%;
        height: 90%;
    }
    .background-butterflies{
        top: 15%;
        left: 3%;
        height: 72%;
    }
    
}

.separator-line{
    display: flex;
    padding: 2% 5% 2% 5%;
}

.notification-message-container .notification-title-container{
    display: flex;
    padding: 2% 5% 2% 5%;
    margin: auto;
}

.notification-title-container .down-arrow-title{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20.34px;
    height: 20.34px;
}


.notification-message-container .notification-date {
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-family: 'Roboto';
    font-size: 11px;
    line-height: 12.89px;
    align-items: center;
    justify-content: space-between;
    color: #73736A;
    padding: 0 5% 2% 5%;
}

.onlyDate {
    justify-content: flex-end !important;
}

.notification-message-container .notification-date .text-date {
    display: flex;
    float: right;
    margin-top: 0;
}

@media screen and (min-width: 1500px) and (max-width: 1620px){
    .notification-message-container .notification-date .text-date {
        display: flex;
        float: right;
        font-size: 8px;
    }

}

.notification-message-container .notification-title {
    display: flex;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    font-family: 'Roboto';
    line-height: 19px;
    color: #000000;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.notification-message-container .notification-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Roboto';
    line-height: 18px;
    display: initial;
    align-items: center;
    color: #000000;
    padding: 0 5% 4% 5%;
}

.notification-message-container .notification-description {
    text-align: justify;
    text-justify: inter-word;
    font-weight: 400;
    font-size: 13px;
    line-height: 15.23px;
    font-family: 'Roboto';
    color: #73736A;
    padding: 0 5% 0 5%;
}

.notification-message-container .notification-documents {
    width: 100%;
    height: 130px;
    padding: 5% 5% 0 5%;
}

.notification-message-container .notification-documents .element-document {
    height: 42px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13px 8px;
    background: #F2F2F2;
    border-radius: 4px;
    margin-bottom: 7px;
}

.notification-message-container .notification-documents .element-document .document-icon {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    color: #0E3B52
}

.notification-message-container .notification-documents .element-document .document-name {
    width: 80%;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #3C3C3B;
    padding-left: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.notification-message-container .notification-documents .element-document .document-options {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.44);
}


.notification-buttons .sign-button {
    padding-left: 4px;
    height: 38px;
    width: auto;
}

.notification-buttons .reject-button {
    padding-right: 4px;
    width: auto;
    height: 38px;
}

.close {
    display: none;
}
