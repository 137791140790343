
.ui.table {
    font-size: 16px !important;
    border: 0 !important;
}

@media only screen and (max-width: 1024px) {
    .ui.table {
        font-size: 14px !important;
    }
}

.ui.inverted.dimmer{
    background-color: rgba(255,255,255,0.98);
}
