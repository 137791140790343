.inputSubject{
    width: 100%;
    margin:0;
    margin-top: 10px;
    height: 40px;
    color: #3C3C3B;
    font-size: 16px;
    outline: none;
    border: none;
    border-bottom: 2px solid #DADADA;
    background: transparent;
}

.inputSubject:focus{
    border-bottom: 2px solid #DADADA !important;
}

.inputSubjectError{
    width: 100%;
    margin:0;
    margin-top: 10px;
    /*padding-left:60px;*/
    height: 40px;
    font-size: 16px;
    outline: none;
    border: none;
    border-bottom: 2px solid #BA3555;
}

.inputSubjectError::placeholder{
    color: #BA3555 !important;
}

.labelInputFormValidate {
    display: block;
    border: 1px solid #B2B2B2;
    border-radius: 4px;
    position: relative;
    box-sizing: border-box;
    transition: .35s;
}

.labelInputFormValidateError {
    display: block;
    border: 1px solid #BA3555;
    border-radius: 4px;
    padding-bottom: 8px;
    position: relative;
    box-sizing: border-box;
    transition: .35s;
}

.messageErrorBottom{
    font-family:  'Roboto';
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    color: #BA3555;
}