#searchProviderAndClient {
  border-radius: 3px !important;
  border: 1px solid #B2B2B2 !important;
  height: 32px !important;
  font-family: 'Roboto' !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #000000 !important;
  background-image: url(../../assets/icons/Shape.png) !important;
  background-repeat: no-repeat !important;
  background-position: 0.3rem 0.3rem !important;
  padding-left: 28px;
}

#searchProviderAndClient:focus {
  border: 1px solid #B2B2B2 !important;
  outline: none !important;
  box-sizing: none !important;
  border-radius: 3px !important;
  outline: none !important;
  font-family: 'Roboto' !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #000000 !important;
  height: 32px !important;
  background-image: none;
}

input #searchProviderAndClient::placeholder {
}