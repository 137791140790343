/*reposo*/
.labelInputFormEntities {
    display: block;
    border: 1px solid #B2B2B2;
    border-radius: 4px;
    position: relative;
    box-sizing: border-box;
    transition: .35s;
}
.labelInputFormEntitiesDisabled {
    display: block;
    border: 1px solid #B2B2B2;
    border-radius: 4px;
    position: relative;
    box-sizing: border-box;
    transition: .35s;
    opacity: 0.3;
}
.labelInputDate {
    display: block;
    border: 1px solid #B2B2B2;
    border-radius: 4px;
    position: relative;
    box-sizing: border-box;
    transition: .35s;
    cursor: pointer;
}

.labelInputFormEntitiesError {
    display: block;
    border: 1px solid #C3132E;
    border-radius: 4px;
    padding: 0;
    position: relative;
    box-sizing: border-box;
    transition: .35s;
}

.labelInputFormDateError {
    display: block;
    border: 1px solid #C3132E;
    border-radius: 4px;
    position: relative;
    box-sizing: border-box;
    transition: .35s;
}
.divInputDropdownFormEntities {
    display: block;
    border: 1px solid #B2B2B2;
    border-radius: 4px;
    height: 44px !important;
    position: relative;
    box-sizing: border-box;
    transition: .35s;
}

.labelInputDropdownSearchFormEntities {
    display: block;
    border: 1px solid #B2B2B2;
    border-radius: 4px;
    height: 44px !important;
    position: relative;
    box-sizing: border-box;
    transition: .35s;
}

.divInputDropdownFormEntitiesError {
    display: block;
    border: 1px solid #C3132E;
    border-radius: 4px;
    height: 44px !important;
    position: relative;
    box-sizing: border-box;
    transition: .35s;
}

.inputFormEntities {
    border: none !important;
    background: transparent !important;
    display: block!important;
    outline: none !important;
    font-family: 'Roboto'!important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 14px !important; 
}
.inputFormEntitiesSearch{
    border: none !important;
    background: transparent !important;
    display: block!important;
    outline: none !important;
    font-family: 'Roboto'!important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 14px !important; 
}


.inputFormEntitiesDV {
    border: none !important;
    background: transparent !important;
    display: block!important;
    outline: none !important;
    font-family: 'Roboto'!important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 14px !important;
    color: #B2B2B2 !important; 
}

.textArea {
    border: none !important;
    background: transparent !important;
    display: block!important;
    outline: none !important;
    font-family: 'Roboto'!important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 21px !important; 
    overflow-y: scroll;
    resize: none !important;
    width: 100%;
    height: 100%;
}

.inputFormDate {
    border: none !important;
    background: transparent !important;
    display: block!important;
    outline: none !important;
    font-family: 'Roboto'!important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 14px !important; 
}

.inputDropdownFormEntities:hover {
    border-bottom: none !important;
    margin-bottom: 15px !important;
}
.inputDropdownFormEntities {
    border: none !important;
    background: transparent !important;
    display: block!important;
    outline: none !important;
    font-family: 'Roboto'!important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 14px !important; 
    width: 100% !important;
}

.labelInputPlaceholder {
    color: #939393 !important;
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px; 
    padding: 8px 10px;
    top: 6px;
    left: 8px;
    position: absolute;
    transition: .35s;
    background-color: white;
    z-index: 2;
}

@media only screen and (max-width: 1024px){
    .labelInputDropdownFormEntities {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
        max-height: 47px !important;
        
    }

    .spanLabelInput {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
    }

    .spanLabelInputDate {
        background-color: #FFFF;
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 14px; 
        padding: 6px 10px;
        top: 10px;
        left: 6px;
        position: absolute;
        transition: .35s;
        z-index: 1;
        width: 70%;
    }
    
    .spanLabelInputDV {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: -1px;
        position: absolute;
        transition: .35s;
    }
}

@media only screen and (max-width: 1366px) and (min-width: 1025px)  {
    .labelInputDropdownFormEntities {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
        max-height: 47px !important;
    }

    .spanLabelInput {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 13px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
    }
    
    .spanLabelInputDate {
        background-color: #FFFF;
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
        z-index: 1;
        width: 66%;
    }

    .labelInputDate {
        display: block;
        border: 1px solid #B2B2B2;
        border-radius: 4px;
        position: relative;
        box-sizing: border-box;
        transition: .35s;
        min-width: 0 !important;
    }
    
    .spanLabelInputDV {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: -1px;
        position: absolute;
        transition: .35s;
    }
}

@media only screen and (max-width: 1919px) and (min-width: 1366px)  {
    .labelInputDropdownFormEntities {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
        z-index: 2 !important;
        max-height: 47px !important;
    }

    .spanLabelInput {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
    }
    
    .spanLabelInputDate {
        background-color: #FFFF;
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
        z-index: 1;
        width: 68%;
    }
    
    .spanLabelInputDV {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: -1px;
        position: absolute;
        transition: .35s;
    }
}

@media only screen and (min-width: 1920px)  {
    .labelInputDropdownFormEntities {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
        max-height: 47px !important;
    }

    .spanLabelInput {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
    }
    
    .spanLabelInputDate {
        background-color: #FFFF;
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: 6px;
        position: absolute;
        transition: .35s;
        width: 70%;
    }
    .spanLabelInputDV {
        color: #939393;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px; 
        padding: 8px 10px;
        top: 6px;
        left: -1px;
        position: absolute;
        transition: .35s;
    }
}
/*Focus*/
.labelInputFormEntities:focus-within {
    border-color: #22C1D0;
}

.divInputDropdownFormEntities:focus-within {
    border-color: #22C1D0;
}
.labelInputDate:focus-within {
    border-color: #22C1D0 !important;
}

.spanLabelInputFocus {
    color: #22C1D0;
}

/*top focus*/
.inputFormEntities:focus ~ span  {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 5px;
    left: 8px;
    top: -9px;
    font-size: 12px;
    background: white;
    color: #22C1D0;
    position: absolute;
}

.inputFormEntitiesSearch:focus ~ span{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 5px;
    left: 8px;
    top: -9px;
    font-size: 12px;
    background: white;
    color: #22C1D0;
    position: absolute;
}

.inputFormEntitiesSearch ~ span{
    align-items: center;
}

.textArea:focus ~ span {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 5px;
    left: 8px;
    top: -9px;
    font-size: 12px;
    background: white;
    color: #22C1D0;
    position: absolute;
}

.textArea:focus ~ p {
    display: none;
}

.inputFormDate:focus ~ span {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 5px;
    left: 8px;
    top: -9px;
    font-size: 12px;
    background: white;
    color: #22C1D0;
    position: absolute;
    width: auto;
}


/*top*/
.spanLabelInputTop {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 5px;
    left: 8px;
    top: -7px;
    font-size: 12px;
    background: white;
    color: #22C1D0;
    position: absolute;
    font-weight: 100;
    line-height: 12px;
}

.spanLabelInputDVTop {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 5px;
    left: 4px;
    top: -10px;
    font-size: 12px;
    background: white;
    color: #22C1D0;
    position: absolute;
}

.labelInputDropdownFormEntitiesTop {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 5px;
    left: 8px;
    top: -10px;
    font-size: 12px;
    background: white;
    color: #22C1D0;
    position: absolute;
    max-height: 47px !important;
}

/*top error*/
.spanLabelInputTopError {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 5px;
    left: 8px;
    top: -10px;
    font-size: 12px;
    font-family: 'Roboto';
    font-weight: 100;
    background: white;
    color: #C3132E;
    position: absolute;
    z-index: 2 !important;
}

.spanLabelInputDVTopError {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 5px;
    left: 4px;
    top: -10px;
    font-size: 12px;
    background: white;
    color: #C3132E;
    position: absolute;
}

.gridColumn {
    padding: 0.7rem !important;
}

.react-switch {
    margin-top: 8px;
    top: 6px !important;
}

.spanSwitch {
    margin-right: 7px;
    color: #000000;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
}

.input-error4 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #C3132E;
    top: 14px;
    left: 12px;
    position: absolute;
    transition: .35s;
    background-color: #FFFF;
}

.input-error3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    color: #C3132E;
    top: 14px;
    left: 16px;
    position: absolute;
    transition: .35s;
    z-index: 2 !important;
}

.input-error2 {
    background: white;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #C3132E;
    top: 30px;
    left: 16px;
    position: absolute;
    transition: .35s;
    
}

.input-textarea-error {
    background: white;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #C3132E;
    
    left: 16px;
    position: absolute;
    transition: .35s;
    
}

input:not([type=checkbox])::-webkit-calendar-picker-indicator{
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url('../../assets/icons/dateIcon.svg') no-repeat;
    width: 20px;
    height: 20px;
    border-width: thin;
    cursor: pointer;
}

.ui.selection.active.dropdown  {
    z-index: 3 !important;
}
.ui.selection.active.dropdown:hover  {
    z-index: 3 !important;
}

.ui.inverted.popup{
    display: none !important;
}

.iconDisable {
    position: absolute;
    top: 10px;
    left: 90%;
}

.labelRadio {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #000000;  
    padding-left: 8px;
}

.classNameDivRadio {
    display: flex !important;
}

.classNameDivRadioDisabled {
    display: flex !important;
    opacity: 0.3;
}

input[type='radio']{
    appearance: none;
    border-radius: 50%;
    border: 1px solid #133F4F;
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin-top: 4px;
 }

input[type="radio"]:checked::after{
    display: inline-block;
    content: "";
    position: relative;
    height: 9px;
    width: 9px;
    background: #133F4F;
    border:1px solid #133F4F;
    border-radius: 50%;
    top: -1.5px;
    left: 2.3px;
}
.icon {
    margin-right: 0 !important; 
}

.ui.selection.dropdown>.delete.icon, .ui.selection.dropdown>.dropdown.icon, .ui.selection.dropdown>.search.icon {
    color: #22C1D0;
    display: inline;
    padding: 12px 0px !important;
}

.ui.selection.active.dropdown .menu {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000 !important;
}

.ui.dropdown .menu > .item {
    color: #000000 !important;;
}

.ui.dropdown .menu {
    top: 90%;
}

.inputFormEntitiesSearch .icon{
    display: none !important;
}

.container-dropdown{
    position: relative;
}
.container-dropdown-disable{
    position: relative;
    opacity: 0.3;
}

.inputDropDownFormEntities {
    border: none !important;
    background: transparent !important;
    display: block!important;
    outline: none !important;
    font-family: 'Roboto'!important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 14px !important; 
    z-index: 3;
}

.labelDropDownFormEntities {
    width: 100%;
    height: 100%;
    display: block;
    border: 1px solid #B2B2B2;
    border-radius: 4px;
    position: absolute;
    box-sizing: border-box;
    transition: .35s;
    top: 0;
}
