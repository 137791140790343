.ui.dropdown>.text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}

.ui.selection.dropdown {
    background: #FFFFFF;
    border: 1px solid #B2B2B2;
    box-sizing: border-box;
    border-radius: 4px;
}

.ui.selection.active.dropdown {
    border-color: #B2B2B2;
}

.ui.selection.active.dropdown .menu {
    background: #FFFFFF;
    border-color: #B2B2B2 !important;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 1.5px;
}

.ui.selection.active.dropdown:hover {
    border-color: #B2B2B2;
    box-shadow: 0 2px 3px 0 rgb(34 36 38 / 15%);
}


.ui.selection.active.dropdown:hover .menu {
    background: #FFFFFF;
    border-color: #B2B2B2 !important;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 1.5px;
}

.ui.dropdown .menu .active.item {
    font-weight: normal !important;
}

.ui.default.dropdown:not(.button)>.text, .ui.dropdown:not(.button)>.default.text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.ui.selection.dropdown>.delete.icon, .ui.selection.dropdown>.dropdown.icon, .ui.selection.dropdown>.search.icon {
    color: #22C1D0;
    display: inline;
}
.ui.selection.active.dropdown:hover {
    border-color: #B2B2B2;
    box-shadow: 0 2px 3px 0 rgb(34 36 38 / 15%);
    background: #FFFFFF;
}

.ui.selection.dropdown:focus {
    border: 1px solid #B2B2B2;
}

.ui.selection.dropdown {
    min-width: 0; 
    min-height: 0;
}

.ui.checkbox {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}
.ui.checkbox .box:before, .ui.checkbox label:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 17px;
    height: 17px;
    content: '';
    background: #fff;
    border-radius: 0.21428571rem;
    transition: border .1s ease,opacity .1s ease,transform .1s ease,box-shadow .1s ease;
    border: 2px solid #133F4F;
}

.ui.checkbox input:checked~.box:before, .ui.checkbox input:checked~label:before {
    background: #fff;
    border-color: #133F4F;
}
.ui.checkbox input:focus~.box:before, .ui.checkbox input:focus~label:before {
    background: #fff;
    border-color: #133F4F;
}

.ui.checkbox .box:active::before, .ui.checkbox label:active::before {
    background: #f9fafb;
    border-color: #133F4F;
}

.ui.checkbox input:checked:focus~.box:before, .ui.checkbox input:checked:focus~label:before, .ui.checkbox input:not([type=radio]):indeterminate:focus~.box:before, .ui.checkbox input:not([type=radio]):indeterminate:focus~label:before {
    background: #fff;
    border-color: #133F4F;
}

.ui.checkbox input:checked~.box:after, .ui.checkbox input:checked~label:after {
    opacity: 1;
    color: #FFFFFF;
    background: #22C1D0;
}
