.dropdownForFocus {
    display:flex;
    flex-wrap: wrap;
    background-color: white;
    box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.2), 0 5px 3px 0 rgba(0, 0, 0, 0.19);
    height: auto;
    padding: 8px 0px 8px 8px;
    width: auto !important;
    max-height:150px;
    z-index: 10 !important;
}

@media only screen and (max-width: 1500px) {
    .dropdownForFocus {
        margin-left:0;
        max-width:100%;
    }
  }

.dropdownForNotFocus {
    display:flex;
    flex-wrap: wrap;
    max-height: 33px;
    width: auto !important;
}

.inputBorderError{
    border-bottom: 2px solid #BA3555 !important;
}

.dropdownForFocusOptions {
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    height: auto;
}

.iconArrowDiv {
    display: none;
}


@media only screen and (max-width: 992px)  {
    .iconArrowDiv{
        right:0;
    }
    
}