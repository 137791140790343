
.textArea-comments {
    border: none !important;
    background: transparent !important;
    display: block!important;
    outline: none !important;
    font-family: 'Roboto'!important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 14px !important; 
    overflow-y: auto;
    resize: none !important;
    width: 100%;
    height: 120px !important;
    padding: 15px;
}


.textArea-comments:focus ~ span {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 5px;
    left: 8px;
    top: -9px;
    font-size: 12px;
    background: white;
    color: #22C1D0;
    position: absolute;
}



.input-textarea-error {
    background: white;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #C3132E;
    
    left: 16px;
    position: absolute;
    transition: .35s;
    
}
