.parentSendNotifications{
  width: 100%;
  max-height: 60vh;
  height: auto;
  position: absolute;
  bottom:0;
  border-radius: 4px 4px 0px 0px;
  overflow-y: hidden;
  display: flex;
  @media only screen and (max-width: 768px){
    width: 100%;
    height: 76.9vh;
    max-height: 76.9vh;
  }
}


.initial{
  height: 64px;
  overflow-y: hidden;
}

.close{
  display: none;
}

.centerTag{
  display: table-cell;
  vertical-align: middle;
}

.headerSendNotifications{
  width:100%;
  height: 64px;
  background:#133F4F;
  display: table;
  position: absolute;
  z-index: 2000;
}

.iconHeader{
  width: 0;
  padding:  0px 16px;
  cursor: pointer;
}

.iconHeaderClose{
  width: 0;
  padding-right: 20px;
  cursor: pointer;
}

.headerText{
  font-weight: 700;
  color: #FFFFFF;
}

.headerTextFinalized{
  font-weight: 700;
  color: #FFFFFF;
  padding-left: 16px;
}

.headerTextFinalized{
  font-weight: 700;
  color: #FFFFFF;
  padding-left: 16px;
}

.contentSendNotifications{
  background: white;
  overflow-y: scroll;
  overflow-x: hidden;
  width:100%;
  height: auto;
  margin-top: 64px;
}

.contentRedirectNotifications{
  width:100%;
  height: 54px;
  display: table;
  border-bottom: 1px  solid #B2B2B2;
}

.textRedirect{
  display: table-cell;
  vertical-align: middle;
  color:#22C1D0;
  text-decoration: underline;
  cursor: pointer;
  padding: 16px;
}

.sendNotificationsData{
  width: 100%;
  height: 104px;
  border-bottom: 1px  solid #B2B2B2;
  display: table;
}

.textSendNotification{
  color: #3C3C3B;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: table-cell;
  vertical-align: middle;
  padding: 30px 15px;
  ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word; 
  @media only screen and (max-width: 1920px){
    width: 55%; 
  } 
  @media only screen and (max-width: 1500px){
    width: 50%; 
  }
  @media only screen and (max-width: 1024px){
    width: 45%; 
    padding: 16px 8px 16px 10.34px;
  }  
  @media only screen and (max-width: 538px){
    width: 55%; 
  }
  @media only screen and (max-width: 437px){
    width: 50%; 
    padding: 16px 8px 16px 10.34px;
  }
  @media only screen and (max-width: 394px){
    width: 45%; 
    padding: 16px 8px 16px 10.34px;
  }
}

p{
  margin-bottom:4px;
}

.loadSendNotification{
  font-weight: 400;
}

.stateNotification{
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: table-cell;
  vertical-align: middle;
  padding-right: 75px;
  text-align: end;
  position: relative;
}

.stateNotificationText{
  display: table-cell;
  vertical-align: middle;
  position: relative;
  text-align: center;
  padding: 0 0 0 27px;
}

.downloadAttachment{
  color:#22C1D0;
  text-decoration: underline;
  font-weight: 400;
  cursor: pointer;
}

.fileCharging{
  color:#22C1D0;
  text-decoration: none;
  font-weight: 400;
}

.fileCharging:hover{
  color:#22C1D0 !important;
  text-decoration: none;
  font-weight: 400;
  cursor: default;
}


.textOnHold{
  font-size: 14px;
  font-weight: 400;
  color: #939393;
}

.closeIconNotification{
  display:none;
  width: 20px;
  height: 20px;
  margin: 3px 0px;
  right: 10px;
  padding: 0;
  
  
}
.sendNotificationsData :hover .closeIconNotification {
  display: inline;
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translate(0, -50%);
  
  
}

.lds-spinner-reportLoading-header {
  color: official;
  display: flex;
  position: relative;
  width: 30px;
  height: 30px;
  top: 8px;
  left: -8px;
  transform: translate(-50%, -50%);

}
.lds-spinner-reportLoading-header div {
  transform-origin: 40px 20px;
  animation: lds-spinner-reportLoading 1.2s linear infinite;
}
.lds-spinner-reportLoading-header div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 2px;
  left: 39px;
  width: 2px;
  height: 11px;
  border-radius: 3.6px;
  background: #ffffff;
}
.lds-spinner-reportLoading-header div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner-reportLoading-header div:nth-child(2) {
  transform: rotate(50deg);
  animation-delay: -1s;
}
.lds-spinner-reportLoading-header div:nth-child(3) {
  transform: rotate(100deg);
  animation-delay: -0.9s;
}
.lds-spinner-reportLoading-header div:nth-child(4) {
  transform: rotate(140deg);
  animation-delay: -0.7s;
}
.lds-spinner-reportLoading-header div:nth-child(5) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner-reportLoading-header div:nth-child(6) {
  transform: rotate(230deg);
  animation-delay: -0.3s;
}
.lds-spinner-reportLoading-header div:nth-child(7) {
  transform: rotate(270deg);
  animation-delay: -0.1s;
}
.lds-spinner-reportLoading-header div:nth-child(8) {
  transform: rotate(310deg);
  animation-delay: 0s;
}
@keyframes lds-spinner-reportLoading {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
  
}



.lds-spinner-notificationLoading {
  color: official;
  display: flex;
  position: relative;
  width: 30px;
  height: 30px;
  top: 8px;
  left: 70%;
  transform: translate(-50%, -50%);

}
.lds-spinner-notificationLoading div {
  transform-origin: 40px 20px;
  animation: lds-spinner-reportLoading 1.2s linear infinite;
}
.lds-spinner-notificationLoading div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 2px;
  left: 39px;
  width: 2px;
  height: 11px;
  border-radius: 3.6px;
  background: #22C1D0;
}
.lds-spinner-notificationLoading div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner-notificationLoading div:nth-child(2) {
  transform: rotate(50deg);
  animation-delay: -1s;
}
.lds-spinner-notificationLoading div:nth-child(3) {
  transform: rotate(100deg);
  animation-delay: -0.9s;
}
.lds-spinner-notificationLoading div:nth-child(4) {
  transform: rotate(140deg);
  animation-delay: -0.7s;
}
.lds-spinner-notificationLoading div:nth-child(5) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner-notificationLoading div:nth-child(6) {
  transform: rotate(230deg);
  animation-delay: -0.3s;
}
.lds-spinner-notificationLoading div:nth-child(7) {
  transform: rotate(270deg);
  animation-delay: -0.1s;
}
.lds-spinner-notificationLoading div:nth-child(8) {
  transform: rotate(310deg);
  animation-delay: 0s;
}
@keyframes lds-spinner-reportLoading {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}