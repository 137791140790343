.pdf-modal {
    background: none !important;
}


@media only screen and (min-width: 1200px){
    .ui.modal {
        width: 100%;
    }
}

@media only screen and (min-width: 768px){
    .ui.modal {
        width: 100%;
    }
}

@media only screen and (min-width: 992px)
{
    .ui.modal {
        width: 100%;
    }
}