.activeIcon {
  font-family: 'Roboto' !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 14px !important;
  text-align: left !important;  
  color:  #3C3C3B !important;
  width: 408px;
  height: 76px;
  box-shadow: 0px 4px 16px 0px #00000040;
  padding: 10px 0px 0px 0px;
  border-radius: 4px 0px 0px 0px;
}
@media only screen and (max-width: 1200px) {
  .activeIcon {
    width: 320px;
    height: 104px;
    box-shadow: 0px 4px 16px 0px #00000040;
    padding: 10px 0px 0px 0px;
    border-radius: 4px 0px 0px 0px;
  }
} 
@media only screen and (max-width: 854px) {
  .activeIcon {
    width: 220px;
    height: 130px;
    box-shadow: 0px 4px 16px 0px #00000040;
    padding: 10px 0px 0px 0px;
    border-radius: 4px 0px 0px 0px;
  }
}
@media only screen and (max-width: 768px) {
  .activeIcon {
    width: 320px;
    height: 115px;
    box-shadow: 0px 4px 16px 0px #00000040;
    padding: 10px 0px 0px 0px;
    border-radius: 4px 0px 0px 0px;
  }
}
@media only screen and (max-width: 545px) {
  .activeIcon {
    width: 180px;
    height: 180px;
    box-shadow: 0px 4px 16px 0px #00000040;
    padding: 10px 0px 0px 0px;
    border-radius: 4px 0px 0px 0px;
  }
}

@media only screen and (max-width: 475px) {
  .activeIcon {
    width: 130px;
    height: 250px;
    box-shadow: 0px 4px 16px 0px #00000040;
    padding: 10px 0px 0px 0px;
    border-radius: 4px 0px 0px 0px;
  }
}

@media only screen and (max-width: 425px) {
  .activeIcon {
    width: 90px;
    height: 310px;
    box-shadow: 0px 4px 16px 0px #00000040;
    padding: 10px 0px 0px 0px;
    border-radius: 4px 0px 0px 0px;
    font-size: 10px !important;
  }
}
.disabledIcon {
  opacity: 0.3;
  margin-left: 5px;
  margin-top: 2px;
}

.actived {
  margin-left: 5px;
  margin-top: 2px;
}