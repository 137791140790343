@media only screen and (max-width: 1024px) and (min-width: 768px)  {
    .ui.column.grid>[class*="seven wide"].column, .ui.grid>.column.row>[class*="seven wide"].column, .ui.grid>.row>[class*="seven wide"].column, .ui.grid>[class*="seven wide"].column {
        width: 34.75%!important;
    }
    
    .ui.column.grid>[class*="one wide"].column, .ui.grid>.column.row>[class*="one wide"].column, .ui.grid>.row>[class*="one wide"].column, .ui.grid>[class*="one wide"].column{
        width: 15.25% !important;
    }
}

@media only screen and (max-width: 1366px) and (min-width: 1025px)  {
    .ui.column.grid>[class*="seven wide"].column, .ui.grid>.column.row>[class*="seven wide"].column, .ui.grid>.row>[class*="seven wide"].column, .ui.grid>[class*="seven wide"].column {
        width: 38.75%!important;
    }
    
    .ui.column.grid>[class*="one wide"].column, .ui.grid>.column.row>[class*="one wide"].column, .ui.grid>.row>[class*="one wide"].column, .ui.grid>[class*="one wide"].column{
        width: 11.25% !important;
    }
}

@media only screen and (max-width: 1440px) and (min-width: 1367px)  {
    .ui.column.grid>[class*="seven wide"].column, .ui.grid>.column.row>[class*="seven wide"].column, .ui.grid>.row>[class*="seven wide"].column, .ui.grid>[class*="seven wide"].column {
        width: 40.75%!important;
    }
    
    .ui.column.grid>[class*="one wide"].column, .ui.grid>.column.row>[class*="one wide"].column, .ui.grid>.row>[class*="one wide"].column, .ui.grid>[class*="one wide"].column{
        width: 9.25% !important;
    }
}

@media only screen and (max-width: 1919px) and (min-width: 1441px)  {
    .ui.column.grid>[class*="seven wide"].column, .ui.grid>.column.row>[class*="seven wide"].column, .ui.grid>.row>[class*="seven wide"].column, .ui.grid>[class*="seven wide"].column {
        width: 40.75%!important;
    }
    
    .ui.column.grid>[class*="one wide"].column, .ui.grid>.column.row>[class*="one wide"].column, .ui.grid>.row>[class*="one wide"].column, .ui.grid>[class*="one wide"].column{
        width: 9.25% !important;
    }
}

@media only screen and (max-width: 2560px) and (min-width: 1920px)  {
    .ui.column.grid>[class*="seven wide"].column, .ui.grid>.column.row>[class*="seven wide"].column, .ui.grid>.row>[class*="seven wide"].column, .ui.grid>[class*="seven wide"].column {
        width: 43.75%!important;
    }
    
    .ui.column.grid>[class*="one wide"].column, .ui.grid>.column.row>[class*="one wide"].column, .ui.grid>.row>[class*="one wide"].column, .ui.grid>[class*="one wide"].column{
        width: 6.25% !important;
    }
    
}

.putButton:disabled{
    background: #c9edf0;
    border: 1px solid #c9edf0;
    color: #FFFFFF;
}

.ui.selection.active.dropdown .menu {
    max-height: 125px;
}