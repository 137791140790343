

.classProgress {
    width: 370px;
    height: 358px;
}

.icon {
    margin-right: 10px !important;
}

.second-message{

    margin: 20px 0 20px 0;
}
