.ui.page.modals.dimmer.active {
    background: rgba(0, 0, 0, 0.5) !important;
} 

.statusUser {
    width: 411px !important;
}

.generalClass {
  width: 477px !important;
}

.modalNoAgreements{
  width: 501px !important;
  height: 452px !important;
  font-weight: 700;
  line-height: 23px !important;
  display: flex;
}
@media only screen and (max-width: 1024px)  {
  .modalNoAgreements{
    width: 401px !important;
    height: 400px !important;
  }
}

@media only screen and (max-width: 768px)  {
  .modalNoAgreements{
    width: 350px!important;
    height: 400px !important;
  }
}
