body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.15rem !important;
    caret-color: #47B2FF;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.buttons-p {
    visibility: hidden;
}

.actions :hover .buttons-p {
    visibility: visible;
}

.buttons-p2 {
    visibility: visible;
}
.actions :hover .buttons-p2 {
    visibility: hidden;
}



.ui[class*="very basic"].table thead tr:first-child th:last-child {
    border-radius: 0 7px 7px 0 !important;
}

.ui[class*="very basic"].table thead tr:first-child th:first-child {
    border-radius: 7px 0 0 7px !important;
}