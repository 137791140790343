.buttonOptionHover:hover {
    background-color: #E4E4E4;
    color: #7C7C74;
}
.buttonOptionHover {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.search-content {
    position: relative;
    width: auto;
    max-width: 640px;
}
.dropdownSearch{
    width: 539px !important;
}
.ui.selection.dropdown {
        border-radius: 3px;
        border: 1px solid #B2B2B2;
        height: 48px !important;
        max-height: 45px !important;
        width: 100% !important;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px;
        margin: 0px 0px;
}
.ui.dropdown>.text {
    width: 420px !important;
    height: 20px !important;
    line-height: 22px!important;
}
.ui.dropdown:not(.button)>.default.text {
    line-height: 22px!important;
}
.ui.default.dropdown:not(.button) > .text, .ui.dropdown:not(.button) > .default.text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #939393 !important;
    margin-left: 0px;
}
.ui.search.dropdown>input.search {
    height: 48px !important;
    max-height: 45px !important;
}
.ui.search.selection.dropdown>input.search {
    line-height: 1.21428571em;
    padding: 0.6em 2.1em 0.67857143em 1em !important;
}
.ui.selection.active.dropdown .menu {
    max-height: 182px !important;
}
.ui.default.dropdown:not(.button) > input:focus ~ .text, .ui.dropdown:not(.button) > input:focus ~ .default.text {
    color: #939393 !important;
}
@media only screen and (max-width: 1024px) {
    .search-content {
        width: auto;
    }
    .inputSearch {
        margin: 0 0 !important;
        height: 40px !important;
    }
    .inputSearchReports {
        margin: 0 0 !important;
        height: 40px !important;
    }
    .dropdownSearch{
        width: 100% !important;
    }
    .ui.selection.dropdown {
        height: 40px !important;
        padding: 0.7em 2.1em 0.78571429em 1em;
    }
    .ui.dropdown>.text {
        width: 90% !important;
        line-height: 17px!important;
    }
    .ui.search.dropdown>input.search {
        height: 40px !important;
    }
    .ui.dropdown:not(.button)>.default.text {
        line-height: 18px!important;
    }
}


.inputSearch {
    border-radius: 3px;
    border: 1px solid #B2B2B2;
    height: 48px;
    width: 100%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14.4px;
    margin: 0px 0px;
    padding-left: 5px;
}

.inputSearchReports {
    border-radius: 3px;
    border: 1px solid #B2B2B2;
    height: 48px;
    width: 100%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    margin: 0px 0px;
    padding-left: 5px;
}


.iconInput {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
}

.iconClose {
    cursor: pointer;
    width: 11px;
    left: unset;
    right: 15px;
}

.iconReReviewed {
    margin-right: 11px;
    margin-left: 11px;
}

@media only screen and (max-width: 1200px) {
    .search-content {
        max-width: 100%;
    }
}

@media only screen and (min-width: 1280px) and (max-width: 1580px) {


}
@media only screen and (min-width: 1042px) and (max-width: 1280px) {

}
@media only screen and (min-width: 1025px) and (max-width: 1041px) {

}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
    .inputSearchSmall {
        width: 378px;
    } 
}
@media only screen and (min-width: 1200px) and (max-width: 1280px) {
    .iconClose {
        right: 10% !important;
    }
}

@media only screen and (min-width: 1800px)  {

}
@media only screen and (max-width: 1800px) {
    .iconClose {
        right: 20px;
    }
}




.inputSearch:focus {
    border: 1px solid #133F4F;
    box-sizing: none;
    border-radius: 3px;
    outline: none;
    padding-left: 20px;
}

.inputSearchReports:focus {
    border: 1px solid #133F4F;
    box-sizing: none;
    border-radius: 3px;
    outline: none;
    padding-left: 20px;
}

.inputSearch::placeholder {
    background-image: url(../../assets/icons/Shape.png);
    background-repeat: no-repeat;
    background-position: 1rem center;
}
.inputSearchReports::placeholder {
    background-image: url(../../assets/icons/Shape.png);
    background-repeat: no-repeat;
    background-position: 1rem center;
}

@media only screen and (min-width: 1280px) and (max-width: 1440px) {
    .icon {
        margin-left: 0px;
    }
}