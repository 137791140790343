.fp-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
}

.sidebar {
    width: 30%;
}

.fp-panel-main {
    width: 70%;
}

.main {
    width: 100%;
    min-height: 100vh;
}

.banner {
    background-color: #282c34;
    background-image: url("/src/assets/img/bannerFondo.png");
    display: flex;
    align-items: center;
    justify-content: center;
}

.center-modal {
    width: 370px;
}