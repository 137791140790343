.iconS {
    display: none;
}

@media only screen and (max-width: 1200px)  {
    .iconS {
        display: inline;
    }
}

.withoutList {
    display: none;
}

.list {
    display: inline;
}