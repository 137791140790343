.ui-material.form.field {
    width: 100%;
}

.ui-material.form .field {
    position: relative;
    width: 100%;
}

.ui-material.form .field input{
    height: 45px;
    width: 100%;
}

.ui-material.form .field label {
    text-align: left;
    letter-spacing: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: #B2B2B2;
    opacity: 1;
    position: absolute;
    left: unset;
    top: 20px;
    transition: .2s ease-in;
}

.ui-material.form .field input:not([type=checkbox]),
.ui-material.form .field textarea {
    font-style: normal;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 16px !important;
    color: #000000;
    border: none;
    border-bottom: 1px solid #133F4F;
    border-radius: unset;
    padding-top: 20px;
    padding-bottom: 5px;
    padding-left: unset;
}

.ui.form .field.field input:-webkit-autofill {
    box-shadow: unset !important;
    border-color: unset !important;
}

.ui.form .field.field input:-webkit-autofill:focus {
    box-shadow: unset !important;
    color: #000000;
}

.ui-material.form .field input:not([type=checkbox])[value=" "],
.ui-material.form .field textarea[value=" "] {
    background: #707070 !important;
}

.ui-material.form .field input:not([type=checkbox]):focus ~ label,
.ui-material.form .field input:not([type=checkbox]):not([value=""]) ~ label,
.ui-material.form .field input:not([type=checkbox]):-webkit-autofill ~ label,
.ui-material.form .field textarea:focus ~ label,
.ui-material.form .field textarea:not([type=checkbox]):not([value=""]) ~ label,
.ui-material.form .field textarea:not([type=checkbox]):-webkit-autofill ~ label {
    top: 2px;
    left: unset;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    color: #B2B2B2;
    font-size: 80%;
    letter-spacing: 0;
}

.ui-material.form .field.checkbox {
    align-items: flex-start;
    align-content: flex-start;
    display: flex;
    margin: .7rem 0;
    position: relative;
}

/*Error*/
.input-error.form .field input:not([type=checkbox]):focus,
.input-error.form .field textarea:focus {
    border-bottom: 1px solid #BA3555 !important;
}

.input-error.form .field input:not([type=checkbox]):hover,
.input-error.form .field textarea:hover {
    border-bottom: 1px solid #BA3555 !important;
}

.labelName{
    color: #B2B2B2;     
}

.input-error.form .field input:not([type=checkbox]),
.input-error.form .field textarea {
    border-bottom: 1px solid #BA3555 !important;
}

.input-error.form .field input:not([type=checkbox]):focus ~ label, .input-error.form .field input:not([type=checkbox]):valid ~ label,
.input-error.form .field textarea:focus ~ label,
.input-error.form .field textarea:valid ~ label {
    top: 2px;
    left: unset;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: #B2B2B2;
    font-size: 80%;
    letter-spacing: 0;
}
.labelError {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 6px;
}


.input-error.form .field label {
    color: #BA3555 !important;
}


