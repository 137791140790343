body ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px !important;
    height: 10px;
}

@media only screen and (max-width: 1024px) and (min-width: 768px)  {
    .ui.column.grid>[class*="four wide"].column, .ui.grid>.column.row>[class*="four wide"].column, .ui.grid>.row>[class*="four wide"].column, .ui.grid>[class*="four wide"].column {
        width: 50%!important;
    }
    .ui.column.grid>[class*="six wide"].column, .ui.grid>.column.row>[class*="six wide"].column, .ui.grid>.row>[class*="six wide"].column, .ui.grid>[class*="six wide"].column {
        width: 57.5%!important;
    }
   .ui.grid [class*="right floated"].column {
    margin-left: 0;
    }
    .ui.column.grid>[class*="three wide"].column, .ui.grid>.column.row>[class*="three wide"].column, .ui.grid>.row>[class*="three wide"].column, .ui.grid>[class*="three wide"].column {
        width: 40%!important;
    }
}

@media only screen and (max-width: 1366px) and (min-width: 1025px)  {
    .ui.column.grid>[class*="four wide"].column, .ui.grid>.column.row>[class*="four wide"].column, .ui.grid>.row>[class*="four wide"].column, .ui.grid>[class*="four wide"].column {
        width: 35%!important;
    }
    .ui.column.grid>[class*="six wide"].column, .ui.grid>.column.row>[class*="six wide"].column, .ui.grid>.row>[class*="six wide"].column, .ui.grid>[class*="six wide"].column {
        width: 40.5%!important;
    }
    ui.column.grid>[class*="three wide"].column, .ui.grid>.column.row>[class*="three wide"].column, .ui.grid>.row>[class*="three wide"].column, .ui.grid>[class*="three wide"].column {
        width: 27%!important;
    }
}

@media only screen and (max-width: 1440px) and (min-width: 1366px)  {
    .ui.column.grid>[class*="three wide"].column, .ui.grid>.column.row>[class*="three wide"].column, .ui.grid>.row>[class*="three wide"].column, .ui.grid>[class*="three wide"].column {
        width: 25%!important;
    }   
    .ui.column.grid>[class*="four wide"].column, .ui.grid>.column.row>[class*="four wide"].column, .ui.grid>.row>[class*="four wide"].column, .ui.grid>[class*="four wide"].column {
        width: 30%!important;
    }
    .ui.grid [class*="right floated"].column {
        margin-left: auto;
    }
}

@media only screen and (max-width: 2560px) and (min-width: 1920px)  {
    .ui.grid [class*="right floated"].column {
        margin-left: auto;
    }
}   

@media only screen and (max-width: 767px) {
    .ui.stackable.grid > .column:not(.row) {
      padding: 1px !important;
    }
    
    .ui.stackable.grid > .column:first-child {
      padding-top: 1rem !important;
    }
    .ui.stackable.grid > .column:last-child {
      padding-bottom: 1rem !important;
    }
  }