.greenHover:hover {
    background-color: #0000000D;
    color: #6D6E71;
}

.greenHover:first-child {
    border-radius: 10px 10px 0 0;
}

.greenHover:last-child {
    border-radius: 0 0 10px 10px;
}

.greenHover {
    color: #706F6F;
    display:flex;
    align-items:center;
    height: 50.5px;
    cursor: pointer;
    position: relative;
}

.optionsContent {
    margin: auto;
    display: flex;
}

@media only screen and (max-width: 769px)  {
    .greenHover{
        width:100%;
        height: 100%;
        cursor: pointer;
        position: relative;
        padding-left: 20px;
    }

    .optionsContent {
        margin: 0;
        display: flex;
    }
}