/*reposo*/
.labelInputFormLogin {
    display: block;
    border: 1px solid #B2B2B2;
    border-radius: 4px;
    position: relative;
    box-sizing: border-box;
    transition: .35s;
    width: 100% !important; 
}
.inputLoginQr{
    background-color: #fff important;
}

.inputFormLogin {
    border: none !important;
    background: transparent !important;
    display: block!important;
    outline: none !important;
    font-family: 'Roboto'!important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 14px !important;
}

.inputFormLoginError {
    border: none !important;
    background: transparent !important;
    display: block!important;
    outline: none !important;
    font-family: 'Roboto'!important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 14px !important;
}

.spanLogin {
    color: #939393;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px; 
    padding: 8px 10px;
    top: 6px;
    left: 6px;
    position: absolute;
    transition: .35s;
}
.spanLoginCode {
    color: #939393;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px; 
    padding: 8px 10px;
    top: 6px;
    left: 30%;
    position: absolute;
    transition: .35s;
}

.spanLoginCode2 {
    color: #939393;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px; 
    padding: 8px 25px;
    top: 6px;
    left: 34%;
    position: absolute;
    transition: .35s;
}
/*Focus*/
.labelInputFormLogin:focus-within {
    border-color: #22C1D0;
}

/*top focus*/
.inputFormLogin:focus ~ span {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 5px;
    left: 8px;
    top: -9px;
    font-size: 12px;
    background: white;
    color: #22C1D0;
    position: absolute;
}

.inputFormLoginError:focus ~ span {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 5px;
    left: 8px;
    top: -9px;
    font-size: 12px;
    background: white;
    color: #C3132E;
    position: absolute;
}

.labelInputFormLoginError {
    display: block;
    border: 1px solid #C3132E;
    border-radius: 4px;
    padding-bottom: 8px;
    position: relative;
    box-sizing: border-box;
    transition: .35s;
}