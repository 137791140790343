.toolbar {
    width: 100%;
    height: 50px;
}

.divCustomPrimaryButton {
    float: left;
    width: 15%;
    height: 40px;
    margin-right: 3%;
    position: absolute;
}

.toolbar-buttons {
    width: 82% !important;
    background: #e0e1e2 !important;
    margin: 0;
    display: flex !important;
    flex-wrap: wrap !important;
    border-radius: 5px;
}


.ui.buttons {
    float: right;
    display: -webkit-box;
    width: 70%;
}

@media only screen and (max-width: 992px)  {
    .divCustomPrimaryButton{
        width: 100%;
        margin:auto;
        position: initial;
        margin: 1rem 0;
    }

    .toolbar-buttons {
        width: 100% !important;
    }

    .ui.buttons {
        float: left;
        width: 100%;
    }
    
}

.content-space {
    margin: 0px 5px;
}

.toolBarEditor .button {
    background: #E4E4E4 0 0 no-repeat padding-box;
}

.ui.buttons .button {
    color: #133F4F;
}

.ui.buttons .button:first-child {
    color: #133F4F;
}


.divCustomPopupButton {
    display: inline;
    margin-right: 9px;
    height: 50px;
}

.ui.button.customPrimaryButton {
    background-color: #22C1D0 !important;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    width: 100%;
    height: 100%;
    padding: revert;
}
